.window {
  border: 1px solid black;
  position: fixed;
  border-radius: 2px;
  top: 0px;
  left: 0px;
  background: white;
  z-index: 1000;
  box-shadow: 2px 2px 0 black;
  width: 16rem;
  font-size: 16px;
}

.window-header {
  background: rgb(238, 238, 238);
  padding: 0.2rem;
  border-bottom: 1px solid black;
}
.window-header [data-draggable="true"] & {
  cursor: grab;
}

.window-header [data-dragging="true"] & {
  cursor: grabbing;
}

.window-body {
  padding: 0.2rem;
  max-height: 20rem;
  overflow-y: auto;
}
