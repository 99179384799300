.calendar {
  width: 100%;
}

.calendar tr {
  border-bottom: 1px solid gray;
}
.calendar tr:last-of-type {
  border-bottom: 0;
}
.calendar tr[data-past="true"] {
  color: gray;
}

.calendar td {
  vertical-align: top;
  padding: 0.3em 0;
}
.calendar td:nth-of-type(1) {
  width: 7rem;
}

.calendar a {
  color: inherit;
  text-decoration: none;
}
.calendar a:hover,
.calendar a:focus {
  text-decoration: underline;
}
