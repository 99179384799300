:root {
  --yellow: rgb(251, 251, 31);
  --purple: #c297ff;
  --black: rgb(6, 6, 7);
}

@font-face {
  font-family: "chicago";
  src: url("../fonts/ChiKareGo.ttf") format("truetype");
}

@font-face {
  font-family: "acumin-variable";
  src: url("../fonts/AcuminVariableConcept.otf");
}

@keyframes blink {
  from {
    color: black;
  }

  to {
    color: var(--yellow);
  }
}

* {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-weight: normal;
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: "acumin-variable", sans-serif;
  background: rgb(250, 250, 250);
}

.world {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -100;
  background: var(--purple);
}

.site-title {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  font-size: 0;
  pointer-events: none;
  transform: translateX(-50%) translateY(-80%);
}
.site-title img {
  width: 30vh;
  display: block;
}

.site-nav a,
.artist-meta a {
  color: rgba(13, 11, 22, 0.9);
  position: fixed;
  letter-spacing: 0.01em;
  font-variation-settings: "wght" 500, "wdth" 105;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 3rem;
  border-radius: 100%;
  z-index: 1000;
  transition: bottom 300ms, top 300ms;
  border: 2px solid transparent;
}

.site-nav a.highlight {
  animation: blink 2s alternate infinite both;
}

.site-nav a:hover,
.site-nav a:focus {
  border: 2px solid black;
}

a.artist {
  left: 2rem;
  top: 2rem;
}

a.readymeals {
  right: 2rem;
  top: 2rem;
}

a.date {
  left: 2rem;
  bottom: 2rem;
}

a.collective {
  right: 2rem;
  bottom: 2rem;
}

[data-bottomchyron="true"] a.collective,
[data-bottomchyron="true"] a.date {
  bottom: 5rem;
}

[data-topchyron="true"] a.artist,
[data-topchyron="true"] a.readymeals {
  top: 5rem;
}

.popover {
  background: black;
  padding: 2rem;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 100;
  width: 100%;
  transform: translateX(-50%) translateY(-100%);
  font-size: var(--ms-2);
  font-size: 2rem;
  border-radius: 40px;
  color: white;
}
