@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.idleOverlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  pointer-events: none;
  transition: 200ms;
}

.idleOverlay img {
  animation: 500ms fade-in both;
  width: 10vw;
  position: fixed;
}
