.editor {
  display: block;
  background-color: rgb(255, 255, 255);
  height: 100vh;
  font-family: chicago;
}

.editor-canvas {
  padding: 1rem;
  padding-left: 0;
}

.editor-toolbar {
  padding: 1rem;
  flex-basis: 8rem;
}

.grid {
  margin: 0 auto;
  display: grid;
  background-color: white;
  border: 1px solid black;
}

.artist-grid {
  display: grid;
  grid-template-columns: repeat(50, 1fr);
  grid-template-rows: repeat(50, calc(100vw / 50));
  width: 100%;
  min-height: 100vh;
}

.object {
  position: relative;
  overflow: hidden;
  z-index: 100;
  user-select: none;
  background-size: cover;
  background-repeat: no-repeat;
}
[data-outlines="true"] & {
  outline: 1px solid rgb(0, 0, 0);
}

[data-tool="edit"] &:hover,
&[data-selected="true"] {
  outline: 2px solid rgb(0, 76, 255);
}

.object.img {
  background: black;
  background-size: cover;
  background-repeat: no-repeat;
}

.object.text {
  color: black;
}

.object video,
.object iframe {
  min-width: 100%;
  min-height: 100%;
  pointer-events: none;
}

.artist-grid .object iframe {
  pointer-events: all !important;
}

.object[data-selected="true"] iframe {
  pointer-events: all;
}

.cursor {
  background: black;
  pointer-events: none;
  display: none;
}

.placeholder {
  z-index: 100;
  pointer-events: none;
  border: 2px solid rgb(21, 255, 0);
  border-radius: 1px;
}
